import { useTranslation } from 'react-i18next';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { AlertBanner, AlertBannerState } from './AlertBanner';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { toCamelCase } from '../utils/stringUtils';
import { ErrorBannerValidationErrorFragment$key } from './__generated__/ErrorBannerValidationErrorFragment.graphql';
import { ErrorBannerInvalidTransitionErrorFragment$key } from './__generated__/ErrorBannerInvalidTransitionErrorFragment.graphql';
import { createSharedStateContext, createSharedStateKey, SharedStateStoreProvider, useSharedState } from '../utils/sharedState';
import { nanoid } from 'nanoid';
import { ErrorBannerJobRevisionIsNotTheLatestErrorFragment$key } from './__generated__/ErrorBannerJobRevisionIsNotTheLatestErrorFragment.graphql';
import { useEffectEvent } from '../utils/effectUtils';

export type HandledError = { __typename: string };
export type TitleProviderFn = () => string;
type ErrorId = string;

type ErrorSharedState = {
  isUnexpectedError: boolean;
  // An array of errors wrapped in relay fragments waiting to be unwrapped
  errors: readonly [ErrorId, HandledError][];
  // An array of unwrapped errors, which can be undefined if they haven't been unwrapped yet
  unwrappedErrors: readonly [ErrorId, ErrorData[] | undefined][];
  titleProvider?: TitleProviderFn;
  propertyNames: readonly string[];
  bannerExpanded: boolean;
};

const initialState: ErrorSharedState = {
  isUnexpectedError: false,
  errors: [],
  unwrappedErrors: [],
  propertyNames: [],
  bannerExpanded: false,
};

const errorSharedStateContext = createSharedStateContext();
const errorSharedStateKey = createSharedStateKey<ErrorSharedState>(() => initialState);

/**
 * Exposes a set of functions allowing to interact with the error banner and its state
 *
 * @returns 3 functions:
 *            1. reportUnexpectedError -> reports an error that is not manageable (aka unexpected)
 *            2. reportHandledError -> reports a set of errors that are manageable (aka expected)
 *            3. dismissError ->  dismiss the error banner,
 *                                the optional parameter allows to reset propertyNames of validation error
 */
export function useErrorBanner(): {
  reportUnexpectedError: (titleProvider: TitleProviderFn) => void;
  reportHandledError: (errors: readonly HandledError[], titleProvider: TitleProviderFn) => void;
  dismissError: (fullReset?: boolean) => void;
} {
  const [, setSharedState] = useSharedState(errorSharedStateContext, errorSharedStateKey);

  const reportUnexpectedError = useCallback(
    (titleProvider: TitleProviderFn) => {
      setSharedState({ ...initialState, isUnexpectedError: true, titleProvider });
    },
    [setSharedState],
  );

  const reportHandledError = useCallback(
    (errors: readonly HandledError[], titleProvider: TitleProviderFn) => {
      setSharedState((state) => ({
        ...state,
        isUnexpectedError: false,
        errors: errors.map((x) => [nanoid(), x] as const),
        unwrappedErrors: [], // Errors were just replaced, clear old unwrapped errors
        titleProvider,
      }));
    },
    [setSharedState],
  );

  const dismissError = useCallback(
    (fullReset?: boolean) => {
      setSharedState((state) => ({ ...initialState, propertyNames: fullReset ? [] : state.propertyNames }));
    },
    [setSharedState],
  );

  return useMemo(
    () => Object.freeze({ reportUnexpectedError, reportHandledError, dismissError }),
    [dismissError, reportHandledError, reportUnexpectedError],
  );
}

export function useErrorPropertyNames() {
  const [{ propertyNames }] = useSharedState(errorSharedStateContext, errorSharedStateKey);

  return useMemo(() => Object.freeze(propertyNames), [propertyNames]);
}

export function ErrorStateProvider({ children }: { children: ReactNode }) {
  return (
    <SharedStateStoreProvider context={errorSharedStateContext}>
      <ErrorStateErrorUnwrappers />
      {children}
    </SharedStateStoreProvider>
  );
}

type SalesApiValidationError = { __typename: 'SalesApiValidationError' } & ErrorBannerValidationErrorFragment$key;

function isSalesApiValidationError(error: HandledError): error is SalesApiValidationError {
  return error.__typename === 'SalesApiValidationError';
}

type InvalidJobStageBaseTransitionError = {
  __typename: 'InvalidJobStageBaseTransitionError';
} & ErrorBannerInvalidTransitionErrorFragment$key;

function isInvalidJobStageBaseTransitionError(error: HandledError): error is InvalidJobStageBaseTransitionError {
  return error.__typename === 'InvalidJobStageBaseTransitionError';
}

type JobRevisionIsNotTheLatestError = {
  __typename: 'JobRevisionIsNotTheLatestError';
} & ErrorBannerJobRevisionIsNotTheLatestErrorFragment$key;

function isJobRevisionIsNotTheLatestError(error: HandledError): error is JobRevisionIsNotTheLatestError {
  return error.__typename === 'JobRevisionIsNotTheLatestError';
}

type ErrorData = {
  code: string;
  params?: Record<string, unknown>;
};

function ErrorStateErrorUnwrappers() {
  const [state, setState] = useSharedState(errorSharedStateContext, errorSharedStateKey);

  return (
    <>
      {state.errors.map(([errorId, error], index) => (
        <ErrorStateErrorUnwrapper
          key={errorId}
          error={error}
          onPropertyNamesChange={(propertyNames) => {
            setState((prevState) => ({ ...prevState, propertyNames }));
          }}
          onUnwrap={(errorData) => {
            setState((prevState) => ({
              ...prevState,
              // Remove the error that was just unwrapped since its fragment might get garbage-collected soon, which
              // would cause Relay errors if we tried to read it again
              errors: prevState.errors.filter(([id]) => id !== errorId),
              unwrappedErrors: Object.assign(prevState.unwrappedErrors.slice(), {
                // Deep copy the error data with structuredClone() to make absolutely sure we don't reference any relay
                // cached data, which would become unavailable after relay garbage collection
                [index]: [errorId, structuredClone(errorData)],
              }),
            }));
          }}
        />
      ))}
    </>
  );
}

function ErrorStateErrorUnwrapper({
  error,
  onPropertyNamesChange,
  onUnwrap,
}: {
  error: HandledError;
  onPropertyNamesChange: Dispatch<ErrorSharedState['propertyNames']>;
  onUnwrap: Dispatch<ErrorData[]>;
}) {
  const validationErrorData = useFragment(
    graphql`
      fragment ErrorBannerValidationErrorFragment on SalesApiValidationError {
        errors {
          propertyName
          code
          index
          comparisonValue
          comparisonPropertyName
          actualValue
        }
      }
    `,
    isSalesApiValidationError(error) ? error : null,
  );

  const invalidTransitionData = useFragment(
    graphql`
      fragment ErrorBannerInvalidTransitionErrorFragment on InvalidJobStageBaseTransitionError {
        __typename
        typeFrom
        typeTo
      }
    `,
    isInvalidJobStageBaseTransitionError(error) ? error : null,
  );

  const jobRevisionNotLatestData = useFragment(
    graphql`
      fragment ErrorBannerJobRevisionIsNotTheLatestErrorFragment on JobRevisionIsNotTheLatestError {
        __typename
        jobRevisionType
      }
    `,
    isJobRevisionIsNotTheLatestError(error) ? error : null,
  );

  const handlePropertyNamesChange = useEffectEvent(onPropertyNamesChange);

  useEffect(() => {
    if (validationErrorData?.errors.length) {
      handlePropertyNamesChange(validationErrorData.errors.map((validationError) => validationError.propertyName));
    }
  }, [handlePropertyNamesChange, validationErrorData]);

  const errorData: ErrorData[] = useMemo(() => {
    if (validationErrorData?.errors.length) {
      return validationErrorData.errors.map((validationError) => ({
        code: `validation.${toCamelCase(validationError.code)}`,
        params: {
          ...validationError,
          index: validationError.index != null ? validationError.index + 1 : undefined,
        },
      }));
    }

    if (invalidTransitionData) {
      return [{ code: toCamelCase(invalidTransitionData.__typename), params: { ...invalidTransitionData } }];
    }

    if (jobRevisionNotLatestData) {
      return [
        {
          code: toCamelCase(jobRevisionNotLatestData.__typename),
          params: {
            ...jobRevisionNotLatestData,
            jobRevisionType: toCamelCase(jobRevisionNotLatestData.jobRevisionType),
          },
        },
      ];
    }

    if (error.__typename) {
      return [{ code: toCamelCase(error.__typename) }];
    }

    return [{ code: 'unknownError' }];
  }, [validationErrorData?.errors, invalidTransitionData, jobRevisionNotLatestData, error.__typename]);

  const handleUnwrap = useEffectEvent(onUnwrap);

  useEffect(() => {
    handleUnwrap(errorData);
  }, [errorData, handleUnwrap]);

  return null;
}

export function ErrorBanner() {
  const { t } = useTranslation('common');
  const { dismissError } = useErrorBanner();
  const [state, setState] = useSharedState(errorSharedStateContext, errorSharedStateKey);

  const showBanner = state.unwrappedErrors.length > 0 || state.isUnexpectedError;

  const bannerState: AlertBannerState = useMemo(
    () => ({ hidden: !showBanner, expanded: state.bannerExpanded, alertId: undefined }),
    [showBanner, state.bannerExpanded],
  );

  const setBannerState = useCallback<Dispatch<SetStateAction<AlertBannerState>>>(
    (action) => {
      const newState = typeof action === 'function' ? action(bannerState) : action;
      if (newState.hidden) {
        dismissError();
      }
      setState((prev) => ({ ...prev, bannerExpanded: newState.expanded }));
    },
    [bannerState, dismissError, setState],
  );

  return (
    <AlertBanner
      state={bannerState}
      setState={setBannerState}
      hidden={!showBanner}
      sx={{ mb: '0.5rem' }}
      title={t(state.titleProvider?.() ?? 'errorMessages.unknownError')}
      severity='error'>
      {!state.isUnexpectedError && (
        <List sx={{ listStyleType: 'disc', paddingTop: 0, marginTop: 0, marginLeft: 0, paddingLeft: '1.1rem' }}>
          {state.unwrappedErrors.map(([errorId, errorData]) => errorData && <ErrorBannerEntry key={errorId} errorData={errorData} />)}
        </List>
      )}
    </AlertBanner>
  );
}

function ErrorBannerEntry({ errorData }: { errorData: ErrorData[] }) {
  const { t } = useTranslation('common');

  return errorData.map((data, i) => (
    <ListItem key={`${data.code}/${i}`} sx={{ display: 'list-item', padding: 0 }}>
      <Typography fontSize='0.8125rem'>{t(`errorMessages.${data.code}`, data.params)}</Typography>
    </ListItem>
  ));
}
