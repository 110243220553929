import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
} from '../../common/utils/forms';
import { CostLine, resetCostLineWorkForceType } from './CostLinesFields';
import { NumberInput, NumberInputProps } from '../../common/components/NumberInput';
import { flagDirty } from '../../common/utils/patchable';
import { ReactNode, SyntheticEvent, useCallback } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { PriceInput, PriceInputProps } from '../../common/components/PriceInput';
import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';
import {
  CostBillingCodeAutocomplete,
  ForwardCostBillingCodeAutocompleteProps,
  SearchBillingCodeInput,
} from '../../common/components/CostBillingCodeAutocomplete';
import { ForwardWorkForceTypeAutocompleteProps, WorkForceTypeAutocomplete } from '../../common/components/WorkForceTypeAutocomplete';
import { asBillingCodeCategory, BillingCodeCategory } from '../../__enums__/BillingCodeCategory';
import { SalesRateResult } from '../../common/types/externalResult';
import { SelectPicker } from '../../common/components/SelectPicker';
import { AdditionalCrane } from '../AdditionalCranesFields';
import { CostLineKind } from '../../__enums__/CostLineKind';
import { Price } from '../../common/utils/price';

export const costLineSubFormContext = createFormContext<{ sync: CostLine }>();

const fieldCostLineIdKey = createFieldKey<string>();
export function useFieldCostLineId(initialValue: string) {
  const [value] = useField(costLineSubFormContext, fieldCostLineIdKey, () => initialValue);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldCostLineBillingCode = ForwardCostBillingCodeAutocompleteProps<false, true>['value'];
const fieldCostLineBillingCodeKey = createFieldKey<FieldCostLineBillingCode>();

export function useFieldCostLineBillingCode(initialValue: FieldCostLineBillingCode, disabled: boolean) {
  const [billingCode, setValue] = useField(costLineSubFormContext, fieldCostLineBillingCodeKey, () => initialValue);
  const setBillingCode = useCallback<SetValueFn<FieldCostLineBillingCode>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const billingCodeIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineBillingCodeKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillingCodeKey);
  useMapper((v, { isDirty }) => flagDirty({ billingCode: v }, { billingCode: isDirty }), [], 'sync');

  const renderBillingCode = useCallback(
    (gridMode: boolean, onlyVisible: boolean, searchInput: SearchBillingCodeInput) => (
      <BillingCodeInput
        value={billingCode}
        onlyVisible={onlyVisible}
        searchInput={searchInput}
        setValue={setBillingCode}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [billingCode, disabled, setBillingCode],
  );

  return { billingCode, setBillingCode, billingCodeIsDirty, renderBillingCode };
}

function BillingCodeInput({
  value,
  setValue,
  gridMode,
  disabled,
  onlyVisible,
  searchInput,
}: {
  value: FieldCostLineBillingCode;
  setValue: SetValueFn<FieldCostLineBillingCode>;
  gridMode: boolean;
  disabled: boolean;
  onlyVisible: boolean;
  searchInput: SearchBillingCodeInput;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardCostBillingCodeAutocompleteProps<false, true>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );

  return (
    <CostBillingCodeAutocomplete
      disableClearable
      disabled={disabled}
      value={value}
      onlyVisible={onlyVisible}
      searchInput={searchInput}
      onChange={handleChange}
      textFieldProps={() => ({
        className: gridMode ? 'borderless' : '',
        label: gridMode ? '' : t('field.cost.billingCode'),
        'data-label-key': 'field.cost.billingCode',
      })}
    />
  );
}

export type FieldCostLineCraneIndex = number;
const fieldCostLineCraneIndexKey = createFieldKey<FieldCostLineCraneIndex>();

export function useFieldCostLineCraneIndex(initialValue: FieldCostLineCraneIndex, disabled: boolean) {
  const [craneIndex, setValue] = useField(costLineSubFormContext, fieldCostLineCraneIndexKey, () => initialValue);
  const setCraneIndex = useCallback<SetValueFn<FieldCostLineCraneIndex>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineCraneIndexKey);
  useMapper((v, { isDirty }) => flagDirty({ craneIndex: v }, { craneIndex: isDirty }), [], 'sync');
  const craneIndexIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineCraneIndexKey);
  const renderCraneIndex = useCallback(
    (additionalCranes: AdditionalCrane[], gridMode: boolean) => (
      <CraneIndexInput
        value={craneIndex}
        setValue={setCraneIndex}
        gridMode={gridMode}
        additionalCranes={additionalCranes}
        disabled={disabled}
      />
    ),
    [craneIndex, disabled, setCraneIndex],
  );

  return { craneIndex, setCraneIndex, craneIndexIsDirty, renderCraneIndex };
}

function CraneIndexInput({
  value,
  setValue,
  gridMode,
  disabled,
  additionalCranes,
}: {
  value: FieldCostLineCraneIndex;
  setValue: SetValueFn<FieldCostLineCraneIndex>;
  gridMode: boolean;
  disabled: boolean;
  additionalCranes: AdditionalCrane[];
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((_: SyntheticEvent, v: FieldCostLineCraneIndex) => setValue(v), [setValue]);

  const options = [0, ...additionalCranes.filter((a) => !a.deletedAt).map((_, index) => index + 1)];

  return (
    <SelectPicker
      value={value}
      getOptionKey={(o) => o}
      getOptionLabel={(o) =>
        o === 0
          ? t(`crane.${gridMode ? 'mainShort' : 'main'}`, { ns: 'jobs' })
          : `${t(`crane.${gridMode ? 'additionalShort' : 'additional'}`, { ns: 'jobs', index: o })}`
      }
      options={options}
      disableClearable
      disabled={disabled}
      onChange={handleChange}
      textFieldProps={() => ({ className: gridMode ? 'borderless' : '', label: gridMode ? '' : t('field.cost.crane') })}
    />
  );
}

export type FieldCostLineKind = CostLineKind;
const fieldCostLineKindKey = createFieldKey<FieldCostLineKind>();

export function useFieldCostLineKind(initialValue: CostLineKind | undefined) {
  const [kind, setKind] = useField(costLineSubFormContext, fieldCostLineKindKey, () => initialValue ?? 'manual');

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineKindKey);
  useMapper((v) => ({ kind: v }), [], 'sync');

  return { kind, setKind };
}

export type FieldCostLineIsFractionAllowed = boolean;
const fieldCostLineFractionAllowedKey = createFieldKey<FieldCostLineIsFractionAllowed>();

export function useFieldCostLineFractionAllowed(initialValue: boolean | undefined) {
  const [costLineIsFractionAllowed, setCostLineIsFractionAllowed] = useField(
    costLineSubFormContext,
    fieldCostLineFractionAllowedKey,
    () => initialValue ?? false,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineFractionAllowedKey);
  useMapper((v) => ({ isFractionAllowed: v }), [], 'sync');

  return { costLineIsFractionAllowed, setCostLineIsFractionAllowed };
}

export type FieldCostLineBillingCodeCategory = BillingCodeCategory | null;
const fieldCostLineBillingCodeCategoryKey = createFieldKey<FieldCostLineBillingCodeCategory>();

export function useFieldCostLineBillingCodeCategory(initialValue: BillingCodeCategory | null) {
  const [billingCodeCategory, setBillingCodeCategory] = useField(
    costLineSubFormContext,
    fieldCostLineBillingCodeCategoryKey,
    () => initialValue,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillingCodeCategoryKey);
  useMapper((v) => ({ billingCodeCategory: v ? asBillingCodeCategory(v) : null }), [], 'sync');

  return { billingCodeCategory, setBillingCodeCategory };
}

export type FieldCostLineIsFixedQuantity = boolean;
const fieldCostLineIsFixedQuantityKey = createFieldKey<FieldCostLineIsFixedQuantity>();
export function useFieldCostLineIsFixedQuantity(initialValue: boolean | undefined) {
  const [costLineIsFixedQuantity, setCostLineIsFixedQuantity] = useField(
    costLineSubFormContext,
    fieldCostLineIsFixedQuantityKey,
    () => initialValue ?? false,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineIsFixedQuantityKey);
  useMapper((v) => ({ isFixedQuantity: v }), [], 'sync');

  return { costLineIsFixedQuantity, setCostLineIsFixedQuantity };
}

export type FieldCostLineDefaultQuantity = number | null;
const fieldCostLineDefaultQuantityKey = createFieldKey<FieldCostLineDefaultQuantity>();
export function useFieldCostLineDefaultQuantity(initialValue: number | null | undefined) {
  const [costLineDefaultQuantity, setCostLineDefaultQuantity] = useField(
    costLineSubFormContext,
    fieldCostLineDefaultQuantityKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineDefaultQuantityKey);
  useMapper((v) => ({ defaultQuantity: v }), [], 'sync');

  return { costLineDefaultQuantity, setCostLineDefaultQuantity };
}

export type FieldCostLineSalesRateResult = SalesRateResult | null;

const fieldCostLineSalesRateResultKey = createFieldKey<FieldCostLineSalesRateResult>();
export function useFieldCostLineSalesRateResult(initialValue: FieldCostLineSalesRateResult | undefined) {
  const [costLineSalesRateResult, setCostLineSalesResultResult] = useField(
    costLineSubFormContext,
    fieldCostLineSalesRateResultKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineSalesRateResultKey);
  useMapper((v, { isDirty }) => flagDirty({ salesRateResult: v }, { salesRateResult: isDirty }), [], 'sync');
  const costLineSalesRateResultIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineSalesRateResultKey);
  return { costLineSalesRateResult, setCostLineSalesResultResult, costLineSalesRateResultIsDirty };
}

export type FieldCostLineRequireWorkForceType = boolean;
const fieldCostLineRequireWorkForceTypeKey = createFieldKey<FieldCostLineRequireWorkForceType>();

export type FieldCostLineWorkForceType = ForwardWorkForceTypeAutocompleteProps['value'];
const fieldCostLineWorkForceTypeKey = createFieldKey<FieldCostLineWorkForceType>();

export function useFieldCostLineWorkForceType(
  initialValue: FieldCostLineWorkForceType,
  initialRequireWorkForceType: FieldCostLineRequireWorkForceType | undefined,
  disabled: boolean,
) {
  const [workForceType, setWorkForceType] = useField(costLineSubFormContext, fieldCostLineWorkForceTypeKey, () => initialValue ?? null);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineWorkForceTypeKey);
  useMapper((v, { isDirty }) => flagDirty({ workForceType: v }, { workForceType: isDirty }), [], 'sync');

  const workForceTypeIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineWorkForceTypeKey);

  const [requireWorkForceType, setRequireWorkForceType] = useField(
    costLineSubFormContext,
    fieldCostLineRequireWorkForceTypeKey,
    () => initialRequireWorkForceType ?? false,
  );

  const useMapperRequireWorkForceType = useFieldMapper(costLineSubFormContext, fieldCostLineRequireWorkForceTypeKey);
  useMapperRequireWorkForceType((v, { isDirty }) => flagDirty({ requireWorkForceType: v }, { requireWorkForceType: isDirty }), [], 'sync');

  const resetWorkForceType = useCallback(
    (category: BillingCodeCategory) => {
      if (disabled) {
        return;
      }

      resetCostLineWorkForceType(
        category,
        {
          workForceType,
          requireWorkForceType,
        },
        { setWorkForceType, setRequireWorkForceType },
      );
    },
    [disabled, requireWorkForceType, setRequireWorkForceType, setWorkForceType, workForceType],
  );

  const renderWorkForceType = useCallback(
    (gridMode: boolean) => (
      <WorkForceTypeInput
        value={workForceType}
        setValue={setWorkForceType}
        gridMode={gridMode}
        disabled={disabled || !requireWorkForceType}
      />
    ),
    [workForceType, setWorkForceType, disabled, requireWorkForceType],
  );

  return {
    workForceType,
    setWorkForceType,
    workForceTypeIsDirty,
    requireWorkForceType,
    setRequireWorkForceType,
    resetWorkForceType,
    renderWorkForceType,
  };
}

function WorkForceTypeInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldCostLineWorkForceType;
  setValue: SetValueFn<FieldCostLineWorkForceType>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardWorkForceTypeAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <WorkForceTypeAutocomplete
      disabled={disabled}
      value={value}
      disableClearable={value !== null}
      onChange={handleChange}
      textFieldProps={() => ({
        className: gridMode ? 'borderless' : '',
        label: gridMode ? '' : t('field.cost.workForceType'),
        placeholder: disabled ? undefined : t('button.select', { ns: 'common' }),
        'data-label-key': 'field.cost.workForceType',
      })}
    />
  );
}

export type FieldCostLineQuantity = NumberInputProps['value'];
const fieldCostLineQuantityKey = createFieldKey<FieldCostLineQuantity>();
export function useFieldCostLineQuantity(initialValue: FieldCostLineQuantity | undefined, disabled: boolean) {
  const [costLineQuantity, setCostLineQuantity] = useField(costLineSubFormContext, fieldCostLineQuantityKey, () => initialValue ?? null);
  const costLineQuantityIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineQuantityKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineQuantityKey);
  useMapper((v, { isDirty }) => flagDirty({ quantity: v }, { quantity: isDirty }), [], 'sync');

  const renderCostLineQuantity = useCallback(
    (
      gridMode: boolean,
      startAdornment: ReactNode,
      isFractionAllowed: boolean,
      billingCodeCategory: BillingCodeCategory | null,
      isFixedQuantity: boolean,
    ) => (
      <CostLineQuantityInput
        value={costLineQuantity}
        setValue={setCostLineQuantity}
        gridMode={gridMode}
        disabled={disabled || isFixedQuantity}
        startAdornment={startAdornment}
        fractionAllowed={isFractionAllowed}
        billingCodeCategory={billingCodeCategory}
      />
    ),
    [costLineQuantity, setCostLineQuantity, disabled],
  );

  return { costLineQuantity, setCostLineQuantity, costLineQuantityIsDirty, renderCostLineQuantity };
}
function CostLineQuantityInput({
  value,
  setValue,
  gridMode,
  disabled,
  startAdornment,
  fractionAllowed,
  billingCodeCategory,
}: {
  value: FieldCostLineQuantity;
  setValue: SetValueFn<FieldCostLineQuantity>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: ReactNode;
  fractionAllowed: boolean;
  billingCodeCategory: BillingCodeCategory | null;
}) {
  const hasErrors = useFieldHasErrors(costLineSubFormContext, fieldCostLineQuantityKey);
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<NumberInputProps['onChange']>>((v) => setValue(v), [setValue]);
  const step = fractionAllowed ? (billingCodeCategory === 'labor' ? 0.25 : 0.01) : 1;
  const min = fractionAllowed ? 0 : 1;
  return (
    <NumberInput
      value={value}
      onChange={handleChange}
      error={hasErrors}
      min={min}
      step={step}
      max={2147483647} // Maximum integer in c#
      borderless={gridMode}
      label={gridMode ? '' : t('field.cost.quantity')}
      InputProps={{
        inputProps: { style: { textAlign: 'right' }, 'data-label-key': 'field.cost.quantity' },
        startAdornment,
      }}
      disabled={disabled}
      required={!disabled}
    />
  );
}

export type FieldCostLineRate = Price | null;
const fieldCostLineRateKey = createFieldKey<FieldCostLineRate>();
export function useFieldCostLineRate(initialValue: FieldCostLineRate | null | undefined, disabled: boolean) {
  const [costLineRate, setCostLineRate] = useField(costLineSubFormContext, fieldCostLineRateKey, () => initialValue ?? null);
  const costLineRateIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineRateKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineRateKey);
  useMapper((v, { isDirty }) => flagDirty({ rate: v }, { rate: isDirty }), [], 'sync');

  const renderCostLineRate = useCallback(
    (gridMode: boolean, startAdornment: (rate: FieldCostLineRate) => ReactNode) => (
      <CostLineRateInput
        value={costLineRate}
        setValue={setCostLineRate}
        gridMode={gridMode}
        disabled={disabled}
        startAdornment={startAdornment}
      />
    ),
    [costLineRate, setCostLineRate, disabled],
  );

  return { costLineRate, setCostLineRate, costLineRateIsDirty, renderCostLineRate };
}
function CostLineRateInput({
  disabled,
  value,
  gridMode,
  setValue,
  startAdornment,
}: {
  value: FieldCostLineRate | null;
  setValue: SetValueFn<FieldCostLineRate | null>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: (rate: FieldCostLineRate) => ReactNode;
}) {
  const hasErrors = useFieldHasErrors(costLineSubFormContext, fieldCostLineRateKey);
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<PriceInputProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <PriceInput
      value={value}
      onChange={handleChange}
      error={hasErrors}
      label={gridMode ? '' : t('field.cost.rate')}
      className={gridMode ? 'borderless' : undefined}
      InputProps={{ startAdornment: startAdornment(value), inputProps: { 'data-label-key': 'field.cost.rate' } }}
      disabled={disabled}
    />
  );
}

export type FieldCostLineBillable = boolean | null;
const fieldCostLineBillableKey = createFieldKey<FieldCostLineBillable>();
export function useFieldCostLineBillable(initialValue: FieldCostLineBillable | undefined, disabled: boolean) {
  const [costLineBillable, setCostLineBillable] = useField(costLineSubFormContext, fieldCostLineBillableKey, () => initialValue ?? false);
  const costLineBillableIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineBillableKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillableKey);
  useMapper((v, { isDirty }) => flagDirty({ billable: v }, { billable: isDirty }), [], 'sync');

  const renderCostLineBillable = useCallback(
    (gridMode: boolean) => (
      <BillableInput value={costLineBillable} setValue={setCostLineBillable} gridMode={gridMode} disabled={disabled} />
    ),
    [costLineBillable, disabled, setCostLineBillable],
  );

  return { costLineBillable, setCostLineBillable, costLineBillableIsDirty, renderCostLineBillable };
}
function BillableInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldCostLineBillable;
  setValue: SetValueFn<FieldCostLineBillable>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<FormControlLabelProps['onChange']>>((_, v) => setValue(v), [setValue]);

  return gridMode ? (
    <Checkbox checked={value ?? false} onChange={handleChange} disabled={disabled} />
  ) : (
    <FormControlLabel
      checked={value ?? false}
      onChange={handleChange}
      control={<Checkbox />}
      label={t('field.cost.billable')}
      disabled={disabled}
    />
  );
}
