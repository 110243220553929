import { createSearchParam, createSearchParamsClass, SearchParamsProps } from '../common/utils/searchParams';
import {
  ARRIVAL_DATE_FORMAT,
  baseJobFilterParams,
  capacitiesSearchParam,
  dispatchBranchesResponsiveGridSearchParam,
  dispatchBranchesSearchParam,
  equipmentKindsResponsiveGridSearchParam,
  equipmentKindsSearchParam,
  JobFiltersSearchParams,
  projectManagersResponsiveGridSearchParam,
  projectManagersSearchParam,
  representativesResponsiveGridSearchParam,
} from '../jobs/JobFilters';
import { isQuoteKind, QuoteKind } from '../__enums__/QuoteKind';
import { isQuoteStatus, QuoteStatus } from '../__enums__/QuoteStatus';
import { QuoteJobRevisionFilterType } from './__generated__/QuoteListFragmentQuery.graphql';

const quoteKindsSearchParam = createSearchParam<readonly QuoteKind[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.KIND).filter(isQuoteKind),
  (kinds, searchParams) => {
    for (const kind of kinds) searchParams.append(JobFiltersSearchParams.KIND, kind);
  },
);

const quoteStatusesSearchParam = createSearchParam<readonly QuoteStatus[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.STATUS).filter(isQuoteStatus),
  (statuses, searchParams) => {
    for (const status of statuses) searchParams.append(JobFiltersSearchParams.STATUS, status);
  },
);

const quoteFiltersParams = {
  ...baseJobFilterParams,
  capacities: capacitiesSearchParam,
  dispatchBranches: dispatchBranchesSearchParam,
  equipmentKinds: equipmentKindsSearchParam,
  kinds: quoteKindsSearchParam,
  projectManagers: projectManagersSearchParam,
  statuses: quoteStatusesSearchParam,
};

export class QuoteFilters extends createSearchParamsClass(quoteFiltersParams) {
  static readonly EMPTY = QuoteFilters.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): QuoteFilters {
    return new QuoteFilters(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: QuoteToResponsiveGridFiltersProps): QuoteResponsiveGridFilters {
    return new QuoteResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): QuoteJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: QuoteJobRevisionFilterType[]): QuoteJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: QuoteJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: QuoteJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.capacities.length
        ? { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: { in: this.props.capacities } } } } } }
        : {},
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate!.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate!.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.dispatchBranches.length
        ? { snapshot: { project: { dispatchBranchId: { in: this.props.dispatchBranches.map(({ id }) => id) } } } }
        : {},
      this.props.equipmentKinds.length
        ? {
            snapshot: {
              equipmentBase: {
                craneSelector: {
                  favoriteConfiguration: { equipmentKindCode: { in: this.props.equipmentKinds.map(({ code }) => code) } },
                },
              },
            },
          }
        : {},
      this.props.kinds.length ? { snapshot: { kind: { in: this.props.kinds } } } : {},
      this.props.projectManagers.length
        ? { snapshot: { clientBase: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const quoteResponsiveGridFiltersParams = {
  ...quoteFiltersParams,
  dispatchBranches: dispatchBranchesResponsiveGridSearchParam,
  equipmentKinds: equipmentKindsResponsiveGridSearchParam,
  projectManagers: projectManagersResponsiveGridSearchParam,
  representatives: representativesResponsiveGridSearchParam,
};

export type QuoteToResponsiveGridFiltersProps = Pick<
  SearchParamsProps<typeof quoteResponsiveGridFiltersParams>,
  'dispatchBranches' | 'equipmentKinds' | 'projectManagers' | 'representatives'
>;

export class QuoteResponsiveGridFilters extends createSearchParamsClass(quoteResponsiveGridFiltersParams) implements QuoteFilters {
  static readonly EMPTY = QuoteFilters.EMPTY.toResponsiveGridFilters({
    dispatchBranches: [],
    equipmentKinds: [],
    projectManagers: [],
    representatives: [],
  });

  toResponsiveGridFilters(props: QuoteToResponsiveGridFiltersProps): QuoteResponsiveGridFilters {
    return new QuoteResponsiveGridFilters({ ...this.props, ...props });
  }

  toJobRevisionFilter(): QuoteJobRevisionFilterType {
    return QuoteFilters.prototype.toJobRevisionFilter.call(this);
  }
}
