import {
  createFieldKey,
  createFormContext,
  FormProvider,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useCallback, useMemo } from 'react';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { ForwardCraneCapacityAutocompleteProps } from '../../common/components/CraneCapacityAutocomplete';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ForwardEquipmentKindAutocompleteProps } from '../../common/components/EquipmentKindAutocomplete';
import { CostBillingCodeAutocomplete, ForwardCostBillingCodeAutocompleteProps } from '../../common/components/CostBillingCodeAutocomplete';
import {
  AutomaticBillingCode,
  AutomaticBillingCodeInput,
  AutomaticBillingCodeWorkForceType,
  AutomaticBillingCodeWorkForceTypeInput,
  billingCodeRuleAutomaticSubFormContext,
  BillingCodeRulesAutomaticRow,
  useAutomaticBillingCodeWorkForceType,
  useFieldAutomaticBillingCode,
  useFieldAutomaticBillingCodeId,
} from './BillingCodeRuleAutomaticSubForm';
import { nanoid } from 'nanoid';
import { ResponsiveGridContent, ResponsiveGridHeader, ResponsiveGridItem } from '../../common/components/ResponsiveGrid';
import { arrSetBy } from '../../common/utils/patchable';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import { castNatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { castSalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { isQuoteKind } from '../../__enums__/QuoteKind';
import { isServiceCallKind } from '../../__enums__/ServiceCallKind';
import { BillingCodeRuleFields_DescriptionFragment$key } from './__generated__/BillingCodeRuleFields_DescriptionFragment.graphql';
import { BillingCodeRuleFields_BillingCodeCollectionFragment$key } from './__generated__/BillingCodeRuleFields_BillingCodeCollectionFragment.graphql';
import { UpsertAutomaticEffectInput } from './__generated__/BillingCodeRuleSaveButtonMutation.graphql';
import { BillingCodeRuleFields_AutomaticGridFragment$key } from './__generated__/BillingCodeRuleFields_AutomaticGridFragment.graphql';
import { BillingCodeRuleFields_BillingCodesVisibleFragment$key } from './__generated__/BillingCodeRuleFields_BillingCodesVisibleFragment.graphql';
import {
  BaseQuestions,
  BaseQuestionsInput,
  CapacitiesInput,
  Clients,
  ClientsInput,
  EquipmentKindsInput,
  NatureOfWork,
  NatureOfWorksAutocompleteInput,
  NatureOfWorkSubCategories,
  NatureOfWorkSubCategoriesInput,
  SaleKinds,
  SaleKindsInput,
  SaleStagesFieldValue,
  SaleStagesInput,
} from '../RuleFields';
import { BillingCodeRuleFields_BaseQuestions$key } from './__generated__/BillingCodeRuleFields_BaseQuestions.graphql';
import { BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment$key } from './__generated__/BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment.graphql';
import { BillingCodeRuleFields_NatureOfWorksFragment$key } from './__generated__/BillingCodeRuleFields_NatureOfWorksFragment.graphql';
import { BillingCodeRuleFields_ClientsFragment$key } from './__generated__/BillingCodeRuleFields_ClientsFragment.graphql';
import { BillingCodeRuleFields_EquipmentKindsFragment$key } from './__generated__/BillingCodeRuleFields_EquipmentKindsFragment.graphql';
import { BillingCodeRuleFields_CapacitiesFragment$key } from './__generated__/BillingCodeRuleFields_CapacitiesFragment.graphql';
import { BillingCodeRuleFields_SaleKindsFragment$key } from './__generated__/BillingCodeRuleFields_SaleKindsFragment.graphql';
import { BillingCodeRuleFields_SaleStagesFragment$key } from './__generated__/BillingCodeRuleFields_SaleStagesFragment.graphql';
import { isSaleStage } from '../../jobs/saleStage';

export interface BillingCodeRuleFieldsMappings {}

export const billingCodeRuleDetailsFormContext = createFormContext<BillingCodeRuleFieldsMappings>();

const fieldDescriptionKey = createFieldKey<string>();

export function useFieldDescription($key: BillingCodeRuleFields_DescriptionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_DescriptionFragment on BillingCodeRule {
        description
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const [description, setDescription] = useField(billingCodeRuleDetailsFormContext, fieldDescriptionKey, () => $data?.description ?? '');

  const useValidationDescription = useFieldValidation(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useValidationDescription((v) => v.trim().length > 0, [], 'save:required');

  const useMapperDescription = useFieldMapper(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useMapperDescription((val) => ({ description: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setDescription(e.target.value);
    },
    [setDescription],
  );

  const errors = useFieldErrors(billingCodeRuleDetailsFormContext, fieldDescriptionKey);

  const renderDescription = useCallback(
    () => (
      <TextField
        label={t('fields.label.description')}
        required={true}
        value={description}
        onChange={handleChange}
        error={!!errors['required']}
      />
    ),
    [description, errors, handleChange, t],
  );

  return { description, setDescription, renderDescription };
}

const fieldSaleStagesKey = createFieldKey<SaleStagesFieldValue>();
export function useFieldSaleStages($key: BillingCodeRuleFields_SaleStagesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_SaleStagesFragment on BillingCodeRule {
        conditions {
          jobConcreteTypes
        }
      }
    `,
    $key,
  );

  const [saleStages, setSaleStages] = useField(
    billingCodeRuleDetailsFormContext,
    fieldSaleStagesKey,
    () => $data?.conditions?.jobConcreteTypes.filter((k) => isSaleStage(k)) ?? [],
  );

  const useMapper = useFieldMapper(billingCodeRuleDetailsFormContext, fieldSaleStagesKey);
  useMapper((val) => ({ upsertConditions: { jobConcreteTypes: val } }), [], 'save');

  const renderSaleStages = useCallback(() => <SaleStagesInput value={saleStages} setValue={setSaleStages} />, [saleStages, setSaleStages]);

  return { saleStages, setSaleStages, renderSaleStages };
}

const fieldSaleKinds = createFieldKey<SaleKinds>();

export function useFieldSaleKinds($key: BillingCodeRuleFields_SaleKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_SaleKindsFragment on BillingCodeRule {
        conditions {
          kinds
        }
      }
    `,
    $key,
  );

  const [saleKinds, setSaleKinds] = useField(
    billingCodeRuleDetailsFormContext,
    fieldSaleKinds,
    () => $data?.conditions?.kinds.filter((k) => isQuoteKind(k) || isServiceCallKind(k)) ?? [],
  );

  const useMapperKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldSaleKinds);
  useMapperKinds((val) => ({ upsertConditions: { kinds: val } }), [], 'save');

  const renderSaleKinds = useCallback(() => <SaleKindsInput value={saleKinds} setValue={setSaleKinds} />, [saleKinds, setSaleKinds]);

  return { saleKinds, setSaleKinds, renderSaleKinds };
}

const fieldCapacitiesKey = createFieldKey<ForwardCraneCapacityAutocompleteProps<true>['value']>();

export function useFieldCapacities($key: BillingCodeRuleFields_CapacitiesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_CapacitiesFragment on BillingCodeRule {
        conditions {
          capacities {
            capacity
            label
          }
        }
      }
    `,
    $key,
  );

  const [capacities, setCapacities] = useField(
    billingCodeRuleDetailsFormContext,
    fieldCapacitiesKey,
    () => $data?.conditions?.capacities ?? [],
  );

  const useMapperCapacities = useFieldMapper(billingCodeRuleDetailsFormContext, fieldCapacitiesKey);
  useMapperCapacities((val) => ({ upsertConditions: { capacities: val?.map((c) => `${c.capacity}`) ?? [] } }), [], 'save');

  const renderCapacities = useCallback(() => <CapacitiesInput value={capacities} setValue={setCapacities} />, [capacities, setCapacities]);

  return { capacities, setCapacities, renderCapacities };
}

const fieldEquipmentKindsKey = createFieldKey<ForwardEquipmentKindAutocompleteProps<true>['value']>();

export function useFieldEquipmentKinds($key: BillingCodeRuleFields_EquipmentKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_EquipmentKindsFragment on BillingCodeRule {
        conditions {
          equipmentKindLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [equipmentKinds, setEquipmentKinds] = useField(
    billingCodeRuleDetailsFormContext,
    fieldEquipmentKindsKey,
    () => $data?.conditions?.equipmentKindLookups ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldEquipmentKindsKey);
  useMapperEquipmentKinds((val) => ({ upsertConditions: { equipmentKinds: val?.map((ek) => `${ek.code}`) ?? [] } }), [], 'save');

  const renderEquipmentKinds = useCallback(
    () => <EquipmentKindsInput value={equipmentKinds} setValue={setEquipmentKinds} />,
    [equipmentKinds, setEquipmentKinds],
  );

  return { equipmentKinds, setEquipmentKinds, renderEquipmentKinds };
}

const fieldClientsKey = createFieldKey<Clients>();
export function useFieldClients($key: BillingCodeRuleFields_ClientsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_ClientsFragment on BillingCodeRule {
        conditions {
          clientEntities {
            id
            name
            location {
              address
            }
            category
            number
            phoneNumber
            representative {
              name
            }
            ...CreditAlertIndicatorFragment
            deletedAt
            externalId
          }
        }
      }
    `,
    $key,
  );

  const [clients, setClients] = useField(billingCodeRuleDetailsFormContext, fieldClientsKey, () => $data?.conditions?.clientEntities ?? []);

  const useMapperClients = useFieldMapper(billingCodeRuleDetailsFormContext, fieldClientsKey);
  useMapperClients((val) => ({ upsertConditions: { clients: val?.map((client) => `${client.externalId}`) } }), [], 'save');

  const renderClients = useCallback(() => <ClientsInput value={clients} setValue={setClients} />, [clients, setClients]);

  return { clients, setClients, renderClients };
}

const fieldNatureOfWorksKey = createFieldKey<NatureOfWork>();
export function useFieldNatureOfWorks($key: BillingCodeRuleFields_NatureOfWorksFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_NatureOfWorksFragment on BillingCodeRule {
        conditions {
          natureOfWorkLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorks, setNatureOfWorks] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorksKey,
    () => $data?.conditions?.natureOfWorkLookups ?? [],
  );

  const useMapperNatureOfWorks = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorksKey);
  useMapperNatureOfWorks((val) => ({ upsertConditions: { natureOfWorks: val.map((v) => `${v.code}`) ?? [] } }), [], 'save');

  const renderNatureOfWorks = useCallback(
    () => <NatureOfWorksAutocompleteInput value={natureOfWorks} setValue={setNatureOfWorks} />,
    [natureOfWorks, setNatureOfWorks],
  );

  return { natureOfWorks, setNatureOfWorks, renderNatureOfWorks };
}

const fieldNatureOfWorkSubCategories = createFieldKey<NatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment on BillingCodeRule {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorkSubCategories,
    () => $data?.conditions.natureOfWorkSubCategories?.map(castNatureOfWorkSubCategoryEnum) ?? [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorkSubCategories);
  useMapperNatureOfWorkSubCategories((val) => ({ upsertConditions: { natureOfWorkSubCategories: val } }), [], 'save');

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoriesInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

const fieldQuestionsBaseKey = createFieldKey<BaseQuestions>();
export function useFieldBaseQuestions($key: BillingCodeRuleFields_BaseQuestions$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BaseQuestions on BillingCodeRule {
        conditions {
          baseQuestions
        }
      }
    `,
    $key,
  );

  const [baseQuestions, setBaseQuestions] = useField(billingCodeRuleDetailsFormContext, fieldQuestionsBaseKey, () =>
    $data?.conditions.baseQuestions ? $data.conditions.baseQuestions.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionsBase = useFieldMapper(billingCodeRuleDetailsFormContext, fieldQuestionsBaseKey);
  useMapperQuestionsBase((val) => ({ upsertConditions: { baseQuestions: val.map((q) => `${q}`) } }), [], 'save');

  const renderBaseQuestions = useCallback(
    () => <BaseQuestionsInput value={baseQuestions} setValue={setBaseQuestions} />,
    [baseQuestions, setBaseQuestions],
  );

  return { baseQuestions, setBaseQuestions, renderBaseQuestions };
}

const fieldBillingCodesVisibleKey = createFieldKey<ForwardCostBillingCodeAutocompleteProps<true>['value']>();

export function useFieldBillingCodesVisible($key: BillingCodeRuleFields_BillingCodesVisibleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BillingCodesVisibleFragment on BillingCodeRule {
        effects {
          billingCodes {
            visible {
              code
              id
              label
              billingSection {
                shortDescription
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const codes = $data?.effects?.billingCodes?.visible ?? [];
  const initialValues = codes.filter((v): v is NonNullable<(typeof codes)[number]> => v != null);
  if (initialValues.length !== codes.length) {
    throw new Error('Unexpected null value. All billing code should be resolved correctly.');
  }

  const [billingCodesVisible, setBillingCodesVisible] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesVisibleKey,
    () => initialValues,
  );

  const useMapperBillingCodesVisible = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesVisibleKey);
  useMapperBillingCodesVisible((val) => ({ upsertEffects: { billingCodeIds: { visible: val?.map((bv) => bv.id) ?? [] } } }), [], 'save');

  const renderBillingCodesVisible = useCallback(
    () => <BillingCodeVisibleInput value={billingCodesVisible} setValue={setBillingCodesVisible} />,
    [billingCodesVisible, setBillingCodesVisible],
  );

  return { billingCodesVisible, setBillingCodesVisible, renderBillingCodesVisible };
}

const emptySearchInput = Object.freeze({
  capacity: '',
  equipmentKindCode: '',
  jobKind: '',
  clientExternalId: '',
  natureOfWorkCode: '',
  natureOfWorkSubCategory: '',
  questionsBase: Object.freeze([]),
  saleStage: '',
});

function BillingCodeVisibleInput({
  value,
  setValue,
}: {
  value: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardCostBillingCodeAutocompleteProps<true>['value']>;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardCostBillingCodeAutocompleteProps<true>['onChange']>>(
    (newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <CostBillingCodeAutocomplete
      multiple
      value={value}
      onlyVisible={false}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.billingCodesVisible'), placeholder: undefined })}
      searchInput={emptySearchInput}
    />
  );
}

const fieldBillingCodesAutomaticKey = createFieldKey<BillingCodeRulesAutomaticRow[]>();
export function useBillingCodeAutomaticCollection($key: BillingCodeRuleFields_BillingCodeCollectionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BillingCodeCollectionFragment on BillingCodeRule {
        effects {
          billingCodes {
            automatic {
              billingCode {
                id
                code
                label
                billingSection {
                  shortDescription
                }
              }
              workForceType {
                id
                code
                label
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesAutomaticKey,
    () =>
      $data?.effects?.billingCodes?.automatic.map(
        (x) =>
          ({
            id: nanoid(),
            billingCode: x.billingCode ?? undefined,
            workForceType: x.workForceType,
          }) satisfies BillingCodeRulesAutomaticRow,
      ) ?? [],
  );

  const useMapper = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesAutomaticKey);
  useMapper(
    (rows) => ({
      upsertEffects: {
        billingCodeIds: {
          automatic: rows
            .filter((x) => x.billingCode)
            .map(
              (row) =>
                ({
                  billingCodeId: row.billingCode!.id,
                  itemTypeCode: row.workForceType?.code ?? null,
                }) satisfies UpsertAutomaticEffectInput,
            ),
        },
      },
    }),
    [],
    'save',
  );

  const replace = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      setBillingCodeAutomaticCollection(arrSetBy(row, (x) => x.id === row.id)(billingCodeAutomaticCollection));
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const append = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      setBillingCodeAutomaticCollection([...billingCodeAutomaticCollection, row]);
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const remove = useCallback(
    (id: DataID) => {
      if (billingCodeAutomaticCollection.some((x) => x.id === id)) {
        setBillingCodeAutomaticCollection(billingCodeAutomaticCollection.filter((x) => x.id !== id));
      }
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  return { billingCodeAutomaticCollection, setBillingCodeAutomaticCollection, replace, append, remove };
}

export function BillingCodesAutomaticGrid({ $key }: { $key: BillingCodeRuleFields_AutomaticGridFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_AutomaticGridFragment on BillingCodeRule {
        ...BillingCodeRuleFields_BillingCodesVisibleFragment
        ...BillingCodeRuleFields_BillingCodeCollectionFragment
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const { billingCodesVisible } = useFieldBillingCodesVisible($data);
  const { billingCodeAutomaticCollection, replace, append, remove: handleRemove } = useBillingCodeAutomaticCollection($data);

  const handleRowChange = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      if (billingCodeAutomaticCollection.find((x) => x.id === row.id)) {
        replace(row);
      } else {
        append(row);
      }
    },
    [append, billingCodeAutomaticCollection, replace],
  );

  return (
    <ResponsiveGridContent formMode gridTemplateColumns='1fr 1fr 0.25fr'>
      <ResponsiveGridHeader
        columnsDefinitions={[
          { id: 'billingCode', label: t('fields.label.billingCode') },
          { id: 'labor', label: t('fields.label.labor') },
          { id: 'actions', label: '' },
        ]}
        sx={{ '& > *': { px: '1rem' } }}
      />
      {billingCodeAutomaticCollection.map((automatic) => (
        <FormProvider context={billingCodeRuleAutomaticSubFormContext} key={automatic.id}>
          <BillingCode_AutomaticRow
            id={automatic.id}
            value={automatic}
            billingCodesVisible={billingCodesVisible}
            onRowChange={handleRowChange}
            onRowDelete={handleRemove}
          />
        </FormProvider>
      ))}
      <FormProvider context={billingCodeRuleAutomaticSubFormContext} key={`new-${billingCodeAutomaticCollection.length}`}>
        <BillingCode_AutomaticRow billingCodesVisible={billingCodesVisible} onRowChange={handleRowChange} id={null} value={null} />
      </FormProvider>
    </ResponsiveGridContent>
  );
}

function BillingCode_AutomaticRow({
  id,
  value,
  billingCodesVisible,
  onRowChange,
  onRowDelete: handleRowDelete,
}: {
  id: DataID | undefined | null;
  value: BillingCodeRulesAutomaticRow | undefined | null;
  billingCodesVisible: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  onRowChange: (row: BillingCodeRulesAutomaticRow) => void;
  onRowDelete?: (id: DataID) => void;
}) {
  const { automaticBillingCodeId } = useFieldAutomaticBillingCodeId(id ?? nanoid());
  const { automaticBillingCode, setAutomaticBillingCode } = useFieldAutomaticBillingCode(value?.billingCode);
  const { automaticBillingCodeWorkForceType, setAutomaticBillingCodeWorkForceType } = useAutomaticBillingCodeWorkForceType(
    value?.workForceType,
  );

  const handleBillingCodeChange = useCallback(
    (billingCode: AutomaticBillingCode) => {
      setAutomaticBillingCodeWorkForceType(null);

      // Voluntary reset workforce to null
      onRowChange({ id: automaticBillingCodeId, billingCode, workForceType: null });
    },
    [automaticBillingCodeId, onRowChange, setAutomaticBillingCodeWorkForceType],
  );

  const handleWorkForceTypeChange = useCallback(
    (workForceType: AutomaticBillingCodeWorkForceType) => {
      onRowChange({ id: automaticBillingCodeId, billingCode: automaticBillingCode, workForceType });
    },
    [automaticBillingCode, automaticBillingCodeId, onRowChange],
  );
  const handleDelete = useMemo(() => (handleRowDelete && id != null ? () => handleRowDelete(id) : undefined), [handleRowDelete, id]);

  return (
    <ResponsiveGridItem id={automaticBillingCodeId}>
      <AutomaticBillingCodeInput
        billingCodesVisible={billingCodesVisible}
        value={id == null ? undefined : automaticBillingCode}
        setValue={id == null ? () => {} : setAutomaticBillingCode}
        onChange={handleBillingCodeChange}
      />
      <AutomaticBillingCodeWorkForceTypeInput
        billingCodeId={automaticBillingCode?.id}
        value={automaticBillingCodeWorkForceType}
        setValue={setAutomaticBillingCodeWorkForceType}
        onChange={handleWorkForceTypeChange}
      />
      <Box>
        {handleDelete && (
          <IconButton onClick={handleDelete} size='small' color='error'>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ResponsiveGridItem>
  );
}
