import {
  createFieldKey,
  createFormContext,
  FormProvider,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
  useFormEtag,
  useFormIsDirty,
  useFormMappings,
} from '../../common/utils/forms';
import { useCallback, useEffect } from 'react';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { ForwardCraneCapacityAutocompleteProps } from '../../common/components/CraneCapacityAutocomplete';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ForwardEquipmentKindAutocompleteProps } from '../../common/components/EquipmentKindAutocomplete';
import { castNatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { castSalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { isQuoteKind } from '../../__enums__/QuoteKind';
import { isServiceCallKind } from '../../__enums__/ServiceCallKind';
import { AccessoryRuleFields_NatureOfWorkFragment$key } from './__generated__/AccessoryRuleFields_NatureOfWorkFragment.graphql';
import { nanoid } from 'nanoid';
import { arrAppend, arrRemove, arrSetBy } from '../../common/utils/patchable';
import { ResponsiveGridContent, ResponsiveGridHeader, ResponsiveGridItem } from '../../common/components/ResponsiveGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  accessoryRuleAutomaticSubFormContext,
  useFieldAutomaticAccessoryAccessoryType,
  useFieldAutomaticAccessoryBillingCode,
  useFieldAutomaticAccessoryId,
} from './AccessoryRuleAutomaticSubForm';
import { AccessoryRuleFields_AutomaticGridFragment$key } from './__generated__/AccessoryRuleFields_AutomaticGridFragment.graphql';
import { AccessoryRuleFields_BillingCodeCollectionFragment$key } from './__generated__/AccessoryRuleFields_BillingCodeCollectionFragment.graphql';
import { UpsertAutomaticAccessoryEffectInput } from './__generated__/AccessoryRuleSaveButtonMutation.graphql';
import {
  BaseQuestions,
  BaseQuestionsInput,
  CapacitiesInput,
  Clients,
  ClientsInput,
  EquipmentKindsInput,
  NatureOfWork,
  NatureOfWorksAutocompleteInput,
  NatureOfWorkSubCategories,
  NatureOfWorkSubCategoriesInput,
  SaleKinds,
  SaleKindsInput,
  SaleStagesFieldValue,
  SaleStagesInput,
} from '../RuleFields';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { AccessoryRuleFields_BaseQuestions$key } from './__generated__/AccessoryRuleFields_BaseQuestions.graphql';
import { AccessoryRuleFields_DescriptionFragment$key } from './__generated__/AccessoryRuleFields_DescriptionFragment.graphql';
import { AccessoryRuleFields_SaleKindsFragment$key } from './__generated__/AccessoryRuleFields_SaleKindsFragment.graphql';
import { AccessoryRuleFields_CapacitiesFragment$key } from './__generated__/AccessoryRuleFields_CapacitiesFragment.graphql';
import { AccessoryRuleFields_EquipmentKindsFragment$key } from './__generated__/AccessoryRuleFields_EquipmentKindsFragment.graphql';
import { AccessoryRuleFields_ClientsFragment$key } from './__generated__/AccessoryRuleFields_ClientsFragment.graphql';
import { AccessoryRuleFields_NatureOfWorkSubCategoriesFragment$key } from './__generated__/AccessoryRuleFields_NatureOfWorkSubCategoriesFragment.graphql';
import { useEffectEvent } from '../../common/utils/effectUtils';
import { isSaleStage } from '../../jobs/saleStage';
import { AccessoryRuleFields_SaleStagesFragment$key } from './__generated__/AccessoryRuleFields_SaleStagesFragment.graphql';

export interface AccessoryRuleFieldsMappings {}

export const accessoryRuleDetailsFormContext = createFormContext<AccessoryRuleFieldsMappings>();

const fieldDescriptionKey = createFieldKey<string>();

export function useFieldDescription($key: AccessoryRuleFields_DescriptionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_DescriptionFragment on AccessoryRule {
        description
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const [description, setDescription] = useField(accessoryRuleDetailsFormContext, fieldDescriptionKey, () => $data?.description ?? '');

  const useValidationDescription = useFieldValidation(accessoryRuleDetailsFormContext, fieldDescriptionKey);
  useValidationDescription((v) => v.trim().length > 0, [], 'save:required');

  const useMapperDescription = useFieldMapper(accessoryRuleDetailsFormContext, fieldDescriptionKey);
  useMapperDescription((val) => ({ description: val.trim() }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setDescription(e.target.value);
    },
    [setDescription],
  );

  const errors = useFieldErrors(accessoryRuleDetailsFormContext, fieldDescriptionKey);

  const renderDescription = useCallback(
    () => (
      <TextField
        label={t('fields.label.description')}
        required={true}
        value={description}
        onChange={handleChange}
        error={!!errors['required']}
      />
    ),
    [description, errors, handleChange, t],
  );

  return { description, setDescription, renderDescription };
}

const fieldSaleStagesKey = createFieldKey<SaleStagesFieldValue>();
export function useFieldSaleStages($key: AccessoryRuleFields_SaleStagesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_SaleStagesFragment on AccessoryRule {
        conditions {
          jobConcreteTypes
        }
      }
    `,
    $key,
  );

  const [saleStages, setSaleStages] = useField(
    accessoryRuleDetailsFormContext,
    fieldSaleStagesKey,
    () => $data?.conditions?.jobConcreteTypes.filter((k) => isSaleStage(k)) ?? [],
  );

  const useMapper = useFieldMapper(accessoryRuleDetailsFormContext, fieldSaleStagesKey);
  useMapper((val) => ({ upsertConditions: { jobConcreteTypes: val } }), [], 'save');

  const renderSaleStages = useCallback(() => <SaleStagesInput value={saleStages} setValue={setSaleStages} />, [saleStages, setSaleStages]);

  return { saleStages, setSaleStages, renderSaleStages };
}

const fieldSaleKindsKey = createFieldKey<SaleKinds>();

export function useFieldSaleKinds($key: AccessoryRuleFields_SaleKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_SaleKindsFragment on AccessoryRule {
        conditions {
          kinds
        }
      }
    `,
    $key,
  );

  const [saleKinds, setSaleKinds] = useField(
    accessoryRuleDetailsFormContext,
    fieldSaleKindsKey,
    () => $data?.conditions?.kinds.filter((k) => isQuoteKind(k) || isServiceCallKind(k)) ?? [],
  );

  const useMapperKinds = useFieldMapper(accessoryRuleDetailsFormContext, fieldSaleKindsKey);
  useMapperKinds((val) => ({ upsertConditions: { kinds: val } }), [], 'save');

  const renderSaleKinds = useCallback(() => <SaleKindsInput value={saleKinds} setValue={setSaleKinds} />, [saleKinds, setSaleKinds]);

  return { saleKinds, setSaleKinds, renderSaleKinds };
}

const fieldCapacitiesKey = createFieldKey<ForwardCraneCapacityAutocompleteProps<true>['value']>();

export function useFieldCapacities($key: AccessoryRuleFields_CapacitiesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_CapacitiesFragment on AccessoryRule {
        conditions {
          capacities {
            capacity
            label
          }
        }
      }
    `,
    $key,
  );

  const [capacities, setCapacities] = useField(
    accessoryRuleDetailsFormContext,
    fieldCapacitiesKey,
    () => $data?.conditions?.capacities ?? [],
  );

  const useMapperCapacities = useFieldMapper(accessoryRuleDetailsFormContext, fieldCapacitiesKey);
  useMapperCapacities((val) => ({ upsertConditions: { capacities: val?.map((c) => `${c.capacity}`) ?? [] } }), [], 'save');

  const renderCapacities = useCallback(() => <CapacitiesInput value={capacities} setValue={setCapacities} />, [capacities, setCapacities]);
  return { capacities, setCapacities, renderCapacities };
}

const fieldEquipmentKindsKey = createFieldKey<ForwardEquipmentKindAutocompleteProps<true>['value']>();

export function useFieldEquipmentKinds($key: AccessoryRuleFields_EquipmentKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_EquipmentKindsFragment on AccessoryRule {
        conditions {
          equipmentKindLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [equipmentKinds, setEquipmentKinds] = useField(
    accessoryRuleDetailsFormContext,
    fieldEquipmentKindsKey,
    () => $data?.conditions?.equipmentKindLookups ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(accessoryRuleDetailsFormContext, fieldEquipmentKindsKey);
  useMapperEquipmentKinds((val) => ({ upsertConditions: { equipmentKinds: val?.map((ek) => `${ek.code}`) ?? [] } }), [], 'save');

  const renderEquipmentKinds = useCallback(
    () => <EquipmentKindsInput value={equipmentKinds} setValue={setEquipmentKinds} />,
    [equipmentKinds, setEquipmentKinds],
  );

  return { equipmentKinds, setEquipmentKinds, renderEquipmentKinds };
}

const fieldClientsKey = createFieldKey<Clients>();

export function useFieldClients($key: AccessoryRuleFields_ClientsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_ClientsFragment on AccessoryRule {
        conditions {
          clientEntities {
            id
            name
            location {
              address
            }
            category
            number
            phoneNumber
            representative {
              name
            }
            ...CreditAlertIndicatorFragment
            deletedAt
            externalId
          }
        }
      }
    `,
    $key,
  );

  const [clients, setClients] = useField(accessoryRuleDetailsFormContext, fieldClientsKey, () => $data?.conditions?.clientEntities ?? []);

  const useMapperClients = useFieldMapper(accessoryRuleDetailsFormContext, fieldClientsKey);
  useMapperClients((val) => ({ upsertConditions: { clients: val?.map((client) => `${client.externalId}`) } }), [], 'save');

  const renderClients = useCallback(() => <ClientsInput value={clients} setValue={setClients} />, [clients, setClients]);

  return { clients, setClients, renderClient: renderClients };
}

const fieldNatureOfWorksKey = createFieldKey<NatureOfWork>();
export function useFieldNatureOfWorks($key: AccessoryRuleFields_NatureOfWorkFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_NatureOfWorkFragment on AccessoryRule {
        conditions {
          natureOfWorkLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorks, setNatureOfWorks] = useField(
    accessoryRuleDetailsFormContext,
    fieldNatureOfWorksKey,
    () => $data?.conditions?.natureOfWorkLookups ?? [],
  );

  const useMapperNatureOfWorks = useFieldMapper(accessoryRuleDetailsFormContext, fieldNatureOfWorksKey);
  useMapperNatureOfWorks((val) => ({ upsertConditions: { natureOfWorks: val.map((v) => `${v.code}`) ?? [] } }), [], 'save');

  const renderNatureOfWorks = useCallback(
    () => <NatureOfWorksAutocompleteInput value={natureOfWorks} setValue={setNatureOfWorks} />,
    [natureOfWorks, setNatureOfWorks],
  );

  return { natureOfWorks, setNatureOfWorks, renderNatureOfWorks };
}

const fieldNatureOfWorkSubCategories = createFieldKey<NatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: AccessoryRuleFields_NatureOfWorkSubCategoriesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_NatureOfWorkSubCategoriesFragment on AccessoryRule {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    accessoryRuleDetailsFormContext,
    fieldNatureOfWorkSubCategories,
    () => $data?.conditions.natureOfWorkSubCategories?.map(castNatureOfWorkSubCategoryEnum) ?? [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(accessoryRuleDetailsFormContext, fieldNatureOfWorkSubCategories);
  useMapperNatureOfWorkSubCategories((val) => ({ upsertConditions: { natureOfWorkSubCategories: val } }), [], 'save');

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoriesInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

const fieldQuestionsBaseKey = createFieldKey<BaseQuestions>();
export function useFieldBaseQuestions($key: AccessoryRuleFields_BaseQuestions$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_BaseQuestions on AccessoryRule {
        conditions {
          baseQuestions
        }
      }
    `,
    $key,
  );

  const [baseQuestions, setBaseQuestions] = useField(accessoryRuleDetailsFormContext, fieldQuestionsBaseKey, () =>
    $data?.conditions.baseQuestions ? $data.conditions.baseQuestions.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionsBase = useFieldMapper(accessoryRuleDetailsFormContext, fieldQuestionsBaseKey);
  useMapperQuestionsBase((val) => ({ upsertConditions: { baseQuestions: val.map((q) => `${q}`) } }), [], 'save');

  const renderBaseQuestions = useCallback(
    () => <BaseQuestionsInput value={baseQuestions} setValue={setBaseQuestions} />,
    [baseQuestions, setBaseQuestions],
  );

  return { baseQuestions, setBaseQuestions, renderBaseQuestions };
}

export type AccessoryRulesAutomaticRow = {
  key: string;
  accessoryType: Readonly<{ id: DataID; code: number; label: Record<string, string> | null | undefined }>;
  billingCode: Readonly<{ id: DataID; code: number; label: Record<string, string> | null | undefined }>;
};
const fieldAccessoryBillingCodeAutomaticCollectionKey = createFieldKey<AccessoryRulesAutomaticRow[]>();
export function useAccessoryBillingCodeAutomaticCollection($key: AccessoryRuleFields_BillingCodeCollectionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_BillingCodeCollectionFragment on AccessoryRule {
        effects {
          accessories {
            automatic {
              accessoryType @required(action: THROW) {
                id
                code
                label
              }
              billingCode @required(action: THROW) {
                id
                code
                label
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const [accessoryBillingCodeAutomaticCollection, setAccessoryBillingCodeAutomaticCollection] = useField(
    accessoryRuleDetailsFormContext,
    fieldAccessoryBillingCodeAutomaticCollectionKey,
    () =>
      $data?.effects.accessories.automatic.map(
        (x) =>
          ({
            key: nanoid(),
            accessoryType: x.accessoryType,
            billingCode: x.billingCode,
          }) satisfies AccessoryRulesAutomaticRow,
      ) ?? [],
  );

  const useMapper = useFieldMapper(accessoryRuleDetailsFormContext, fieldAccessoryBillingCodeAutomaticCollectionKey);
  useMapper(
    (rows) => ({
      upsertEffects: {
        accessories: {
          automatic: rows.map(
            (row) =>
              ({
                accessoryTypeCode: row.accessoryType.code,
                frequencyBillingCodeId: row.billingCode.id,
              }) satisfies UpsertAutomaticAccessoryEffectInput,
          ),
        },
      },
    }),
    [],
    'save',
  );

  const appendAccessoryBillingCodeAutomatic = useCallback(
    (row: AccessoryRulesAutomaticRow) => {
      setAccessoryBillingCodeAutomaticCollection(arrAppend({ ...row, key: nanoid() }));
    },
    [setAccessoryBillingCodeAutomaticCollection],
  );

  const replaceAccessoryBillingCodeAutomatic = useCallback(
    (row: AccessoryRulesAutomaticRow) => {
      setAccessoryBillingCodeAutomaticCollection(arrSetBy(row, (x) => x.key === row.key));
    },
    [setAccessoryBillingCodeAutomaticCollection],
  );

  const removeAccessoryBillingCodeAutomatic = useCallback(
    (key: string) => {
      setAccessoryBillingCodeAutomaticCollection(arrRemove((v) => v.key === key));
    },
    [setAccessoryBillingCodeAutomaticCollection],
  );

  const renderAccessoryBillingCodeAutomaticCollection = useCallback(() => {
    const handleChange = replaceAccessoryBillingCodeAutomatic;
    const handleDelete = removeAccessoryBillingCodeAutomatic;

    return accessoryBillingCodeAutomaticCollection.map((a) => (
      <FormProvider key={a.key} context={accessoryRuleAutomaticSubFormContext}>
        <AccessoryRule_Item id={a.key} value={a} onChange={handleChange} onDelete={handleDelete} />
      </FormProvider>
    ));
  }, [accessoryBillingCodeAutomaticCollection, removeAccessoryBillingCodeAutomatic, replaceAccessoryBillingCodeAutomatic]);

  const renderAccessoryBillingCodeAutomaticNewItem = useCallback(() => {
    const handleChange = appendAccessoryBillingCodeAutomatic;

    return (
      <FormProvider key={`new-${accessoryBillingCodeAutomaticCollection.length}`} context={accessoryRuleAutomaticSubFormContext}>
        <AccessoryRule_Item id='new' value={null} onChange={handleChange} onDelete={() => {}} />
      </FormProvider>
    );
  }, [accessoryBillingCodeAutomaticCollection.length, appendAccessoryBillingCodeAutomatic]);

  return {
    accessoryBillingCodeAutomaticCollection,
    setAccessoryBillingCodeAutomaticCollection,
    appendAccessoryBillingCodeAutomatic,
    replaceAccessoryBillingCodeAutomatic,
    removeAccessoryBillingCodeAutomatic,
    renderAccessoryBillingCodeAutomaticCollection,
    renderAccessoryBillingCodeAutomaticNewItem,
  };
}

export function AccessoryBillingCodesAutomaticGrid({ $key }: { $key: AccessoryRuleFields_AutomaticGridFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment AccessoryRuleFields_AutomaticGridFragment on AccessoryRule {
        ...AccessoryRuleFields_BillingCodeCollectionFragment
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const { renderAccessoryBillingCodeAutomaticCollection, renderAccessoryBillingCodeAutomaticNewItem } =
    useAccessoryBillingCodeAutomaticCollection($data);

  return (
    <ResponsiveGridContent formMode gridTemplateColumns='1fr 1fr 0.25fr'>
      <ResponsiveGridHeader
        columnsDefinitions={[
          { id: 'billingCode', label: t('fields.label.accessories') },
          { id: 'labor', label: t('fields.label.billed') },
          { id: 'actions', label: '' },
        ]}
        sx={{ '& > *': { px: '1rem' } }}
      />
      {renderAccessoryBillingCodeAutomaticCollection()}
      {renderAccessoryBillingCodeAutomaticNewItem()}
    </ResponsiveGridContent>
  );
}

function AccessoryRule_Item({
  id,
  value,
  onChange,
  onDelete,
}: {
  id: DataID;
  value: AccessoryRulesAutomaticRow | null | undefined;
  onChange: (row: AccessoryRulesAutomaticRow) => void;
  onDelete: (id: DataID) => void;
}) {
  const { automaticAccessoryId } = useFieldAutomaticAccessoryId(id);
  const { automaticAccessoryAccessoryType, renderAutomaticAccessoryType } = useFieldAutomaticAccessoryAccessoryType(value?.accessoryType);
  const { automaticAccessoryBillingCode, renderAutomaticAccessoryBillingCode } = useFieldAutomaticAccessoryBillingCode(
    value?.billingCode,
    !automaticAccessoryAccessoryType,
  );

  const { mapAll } = useFormMappings(accessoryRuleAutomaticSubFormContext);
  const sync = useEffectEvent((formState: unknown) => {
    // Enforce a pseudo dependency on current form state since mapAll is a stable function.
    // This is usually provided as the form etag.
    if (!formState) {
      return;
    }

    onChange(mapAll('sync'));
  });

  const formEtag = useFormEtag(accessoryRuleAutomaticSubFormContext);
  const formDirty = useFormIsDirty(accessoryRuleAutomaticSubFormContext);
  useEffect(() => {
    if (!formDirty) {
      return;
    }

    if (!automaticAccessoryAccessoryType || !automaticAccessoryBillingCode) {
      return;
    }

    sync(formEtag);
  }, [automaticAccessoryAccessoryType, automaticAccessoryBillingCode, formDirty, formEtag, sync]);

  const handleDelete = useCallback(() => onDelete(id), [id, onDelete]);

  return (
    <ResponsiveGridItem id={automaticAccessoryId}>
      {renderAutomaticAccessoryType()}
      {renderAutomaticAccessoryBillingCode()}
      <Box>
        {id !== 'new' && (
          <IconButton onClick={handleDelete} size='small' color='error'>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ResponsiveGridItem>
  );
}
