import { createFieldKey, createFormContext, SetValueFn, useField, useFieldIsDirty, useFieldMapper } from '../common/utils/forms';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BoomConfigurationAutocomplete,
  ForwardBoomConfigurationAutocompleteProps,
} from '../common/components/BoomConfigurationAutocomplete';
import { AdditionalCrane } from './AdditionalCranesFields';
import { ForwardCraneVehicleIdAutocompleteProps } from '../common/components/CraneVehicleIdAutocomplete';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../common/components/CraneCapacityAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import {
  CraneConfigurationKindAutocomplete,
  ForwardCraneConfigurationKindAutocompleteProps,
} from '../common/components/CraneConfigurationKindAutocomplete';
import { Switch } from '@mui/material';
import { DateTime } from 'luxon';
import { flagDirty } from '../common/utils/patchable';

export const additionalCranesSubFormContext = createFormContext<{ sync: AdditionalCrane }>();

const fieldAdditionalCranesIdKey = createFieldKey<string>();
export function useFieldAdditionalCranesId(initialValue: string) {
  const [value] = useField(additionalCranesSubFormContext, fieldAdditionalCranesIdKey, () => initialValue);

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldAdditionalCranesBoomConfiguration = ForwardBoomConfigurationAutocompleteProps['value'];
const fieldAdditionalCranesBoomConfigurationKey = createFieldKey<FieldAdditionalCranesBoomConfiguration>();
export function useFieldAdditionalCranesBoomConfiguration(
  initialValue: FieldAdditionalCranesBoomConfiguration,
  disabled: boolean,
  required: boolean,
) {
  const [additionalCranesBoomConfiguration, setAdditionalCranesBoomConfiguration] = useField(
    additionalCranesSubFormContext,
    fieldAdditionalCranesBoomConfigurationKey,
    () => initialValue,
  );

  const additionalCranesBoomConfigurationIsDirty = useFieldIsDirty(
    additionalCranesSubFormContext,
    fieldAdditionalCranesBoomConfigurationKey,
  );

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesBoomConfigurationKey);
  useMapper((v, { isDirty }) => flagDirty({ boomConfiguration: v }, { boomConfiguration: isDirty }), [], 'sync');

  const renderAdditionalCranesBoomConfiguration = useCallback(
    (capacity: number, configurationKindCode: number, equipmentKindCode: number) => (
      <BoomConfigurationInput
        value={additionalCranesBoomConfiguration}
        setValue={setAdditionalCranesBoomConfiguration}
        capacity={capacity}
        configurationKindCode={configurationKindCode}
        equipmentKindCode={equipmentKindCode}
        disabled={disabled}
        required={required}
      />
    ),
    [additionalCranesBoomConfiguration, setAdditionalCranesBoomConfiguration, disabled, required],
  );

  return {
    additionalCranesBoomConfiguration,
    setAdditionalCranesBoomConfiguration,
    renderAdditionalCranesBoomConfiguration,
    additionalCranesBoomConfigurationIsDirty,
  };
}

function BoomConfigurationInput({
  value,
  setValue,
  capacity,
  configurationKindCode,
  equipmentKindCode,
  disabled,
  required,
}: {
  value: FieldAdditionalCranesBoomConfiguration;
  setValue: SetValueFn<FieldAdditionalCranesBoomConfiguration>;
  capacity: number;
  configurationKindCode: number;
  equipmentKindCode: number;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const handleChange = useCallback<NonNullable<ForwardBoomConfigurationAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <BoomConfigurationAutocomplete
      capacity={capacity}
      configurationKindCode={configurationKindCode}
      equipmentKindCode={equipmentKindCode}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.equipment.boomConfiguration'),
        required,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

export type FieldAdditionalCranesCapacity = ForwardCraneCapacityAutocompleteProps['value'];
const fieldAdditionalCranesCapacityKey = createFieldKey<FieldAdditionalCranesCapacity>();
export function useFieldAdditionalCranesCapacity(initialValue: FieldAdditionalCranesCapacity, disabled: boolean, required: boolean) {
  const [additionalCranesCapacity, setAdditionalCranesCapacity] = useField(
    additionalCranesSubFormContext,
    fieldAdditionalCranesCapacityKey,
    () => initialValue,
  );

  const additionalCranesCapacityIsDirty = useFieldIsDirty(additionalCranesSubFormContext, fieldAdditionalCranesCapacityKey);

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesCapacityKey);
  useMapper((v, { isDirty }) => flagDirty({ capacity: v }, { capacity: isDirty }), [], 'sync');

  const renderAdditionalCranesCapacity = useCallback(
    (
      configurationKindCode: number | null,
      equipmentKindCode: number | null,
      vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value'],
    ) => (
      <AdditionalCranesCapacityInput
        value={additionalCranesCapacity}
        setValue={setAdditionalCranesCapacity}
        configurationKindCode={configurationKindCode}
        equipmentKindCode={equipmentKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [additionalCranesCapacity, setAdditionalCranesCapacity, disabled, required],
  );

  return { additionalCranesCapacity, setAdditionalCranesCapacity, additionalCranesCapacityIsDirty, renderAdditionalCranesCapacity };
}

function AdditionalCranesCapacityInput({
  value,
  setValue,
  configurationKindCode,
  equipmentKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldAdditionalCranesCapacity;
  setValue: SetValueFn<FieldAdditionalCranesCapacity>;
  configurationKindCode: number | null;
  equipmentKindCode: number | null;
  vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value'];
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const handleChange = useCallback<NonNullable<ForwardCraneCapacityAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <CraneCapacityAutocomplete
      configurationKindCode={configurationKindCode}
      equipmentKindCode={equipmentKindCode}
      vehicleIds={vehicleIds?.map((v) => v.key) ?? null}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.equipment.capacity'),
        required,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

export type FieldAdditionalCranesEquipmentKind = ForwardEquipmentKindAutocompleteProps['value'];
const fieldAdditionalCranesEquipmentKindKey = createFieldKey<FieldAdditionalCranesEquipmentKind>();
export function useFieldAdditionalCranesEquipmentKind(
  initialValue: FieldAdditionalCranesEquipmentKind,
  disabled: boolean,
  required: boolean,
) {
  const [additionalCranesEquipmentKind, setAdditionalCranesEquipmentKind] = useField(
    additionalCranesSubFormContext,
    fieldAdditionalCranesEquipmentKindKey,
    () => initialValue,
  );

  const additionalCranesEquipmentKindIsDirty = useFieldIsDirty(additionalCranesSubFormContext, fieldAdditionalCranesEquipmentKindKey);

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesEquipmentKindKey);
  useMapper((v, { isDirty }) => flagDirty({ equipmentKind: v }, { equipmentKind: isDirty }), [], 'sync');

  const renderAdditionalCranesEquipmentKind = useCallback(
    (capacity: number | null, configurationKindCode: number | null, vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value']) => (
      <AdditionalCranesEquipmentKindInput
        value={additionalCranesEquipmentKind}
        setValue={setAdditionalCranesEquipmentKind}
        capacity={capacity}
        configurationKindCode={configurationKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [additionalCranesEquipmentKind, setAdditionalCranesEquipmentKind, disabled, required],
  );

  return {
    additionalCranesEquipmentKind,
    setAdditionalCranesEquipmentKind,
    additionalCranesEquipmentKindIsDirty,
    renderAdditionalCranesEquipmentKind,
  };
}

function AdditionalCranesEquipmentKindInput({
  value,
  setValue,
  capacity,
  configurationKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldAdditionalCranesEquipmentKind;
  setValue: SetValueFn<FieldAdditionalCranesEquipmentKind>;
  capacity: number | null;
  configurationKindCode: number | null;
  vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value'];
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const handleChange = useCallback<NonNullable<ForwardEquipmentKindAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <EquipmentKindAutocomplete
      capacity={capacity}
      configurationKindCode={configurationKindCode}
      vehicleIds={vehicleIds?.map((v) => v.key) ?? null}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.equipment.type'),
        required,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

export type FieldAdditionalCranesConfigurationKind = ForwardCraneConfigurationKindAutocompleteProps['value'];
const fieldAdditionalCranesConfigurationKindKey = createFieldKey<FieldAdditionalCranesConfigurationKind>();
export function useFieldAdditionalCranesConfigurationKind(
  initialValue: FieldAdditionalCranesConfigurationKind,
  disabled: boolean,
  required: boolean,
) {
  const [additionalCranesConfigurationKind, setAdditionalCranesConfigurationKind] = useField(
    additionalCranesSubFormContext,
    fieldAdditionalCranesConfigurationKindKey,
    () => initialValue,
  );

  const additionalCranesConfigurationKindIsDirty = useFieldIsDirty(
    additionalCranesSubFormContext,
    fieldAdditionalCranesConfigurationKindKey,
  );

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesConfigurationKindKey);
  useMapper((v, { isDirty }) => flagDirty({ configurationKind: v }, { configurationKind: isDirty }), [], 'sync');

  const renderAdditionalCranesConfigurationKind = useCallback(
    (capacity: number | null, equipmentKindCode: number | null, vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value']) => (
      <AdditionalCranesConfigurationKindInput
        value={additionalCranesConfigurationKind}
        setValue={setAdditionalCranesConfigurationKind}
        capacity={capacity}
        equipmentKindCode={equipmentKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [additionalCranesConfigurationKind, setAdditionalCranesConfigurationKind, disabled, required],
  );

  return {
    additionalCranesConfigurationKind,
    setAdditionalCranesConfigurationKind,
    additionalCranesConfigurationKindIsDirty,
    renderAdditionalCranesConfigurationKind,
  };
}

function AdditionalCranesConfigurationKindInput({
  value,
  setValue,
  capacity,
  equipmentKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldAdditionalCranesConfigurationKind;
  setValue: SetValueFn<FieldAdditionalCranesConfigurationKind>;
  capacity: number | null;
  equipmentKindCode: number | null;
  vehicleIds: ForwardCraneVehicleIdAutocompleteProps<true>['value'];
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const handleChange = useCallback<NonNullable<ForwardCraneConfigurationKindAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <CraneConfigurationKindAutocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.equipment.configurationOption'),
        required,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
      equipmentKindCode={equipmentKindCode}
      vehicleIds={vehicleIds?.map((v) => v.key) ?? null}
      capacity={capacity}
    />
  );
}

const fieldAdditionalCranesActiveKey = createFieldKey<DateTime | null>();
export function useFieldAdditionalCranesActive(initialValue: DateTime | null | undefined, disabled: boolean) {
  const [additionalCranesDeletedAt, setAdditionalCranesDeletedAt] = useField(
    additionalCranesSubFormContext,
    fieldAdditionalCranesActiveKey,
    () => initialValue ?? null,
  );
  const additionalCranesActive = !additionalCranesDeletedAt;

  const additionalCranesActiveIsDirty = useFieldIsDirty(additionalCranesSubFormContext, fieldAdditionalCranesActiveKey);

  const useMapper = useFieldMapper(additionalCranesSubFormContext, fieldAdditionalCranesActiveKey);
  useMapper((v, { isDirty }) => flagDirty({ deletedAt: v }, { deletedAt: isDirty }), [], 'sync');

  const activate = useCallback(() => setAdditionalCranesDeletedAt(null), [setAdditionalCranesDeletedAt]);
  const deactivate = useCallback(() => setAdditionalCranesDeletedAt(DateTime.now()), [setAdditionalCranesDeletedAt]);

  const renderAdditionalCranesActive = useCallback(
    () => <AdditionalCranesActive value={additionalCranesDeletedAt} activate={activate} deactivate={deactivate} disabled={disabled} />,
    [additionalCranesDeletedAt, activate, deactivate, disabled],
  );

  return {
    additionalCranesActive,
    additionalCranesActiveIsDirty,
    renderAdditionalCranesActive,
  };
}

function AdditionalCranesActive({
  value,
  activate,
  deactivate,
  disabled,
}: {
  value: DateTime | null;
  activate: () => void;
  deactivate: () => void;
  disabled: boolean;
}) {
  const handleToggle = useCallback(
    (checked: boolean) => {
      if (checked) {
        activate();
      } else {
        deactivate();
      }
    },
    [activate, deactivate],
  );

  return (
    <Switch
      color='secondary'
      checked={!value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => handleToggle(event.target.checked)}
      disabled={disabled}
    />
  );
}
