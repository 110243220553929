import { Box, ListItemIcon, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { useCallback, useMemo, useRef } from 'react';
import { ServiceCallStatusChip } from './ServiceCallStatusChip';
import { ServiceCallListFragment$key } from './__generated__/ServiceCallListFragment.graphql';
import { castServiceCallKind, serviceCallKinds } from '../__enums__/ServiceCallKind';
import { ServiceCallListFiltersFragment$key } from './__generated__/ServiceCallListFiltersFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ServiceCallListDelayButton } from './ServiceCallListDelayButton';
import { ServiceCallListActionsFragment$key } from './__generated__/ServiceCallListActionsFragment.graphql';
import { isServiceCallStatus, serviceCallStatuses } from '../__enums__/ServiceCallStatus';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  TextSearchFilter,
} from '../jobs/JobFilters';
import { RequireWrite } from '../auth/Authorization';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';
import { ServiceCallList_ItemFragment$key } from './__generated__/ServiceCallList_ItemFragment.graphql';
import { ServiceCallList_RowFragment$key } from './__generated__/ServiceCallList_RowFragment.graphql';
import { ServiceCallFilters, ServiceCallResponsiveGridFilters } from './ServiceCallFilters';
import { ServiceCallListFragmentQuery } from './__generated__/ServiceCallListFragmentQuery.graphql';
import { CreditCategoryChip } from '../jobs/CreditCategoryChip';

export interface ServiceCallListProps extends ResponsiveGridForwardProps {
  $key: ServiceCallListFragment$key;
  filters$key: ServiceCallListFiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: ServiceCallFilters;
  onFiltersChange: (filters: ServiceCallFilters) => void;
}

export function ServiceCallList({
  $key,
  filters$key,
  write$key,
  filters,
  onFiltersChange: handleFiltersChange,
  onItemClick,
  ...gridProps
}: ServiceCallListProps) {
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));

  const [$data, refetch] = useRefetchableFragment<ServiceCallListFragmentQuery, ServiceCallListFragment$key>(
    graphql`
      fragment ServiceCallListFragment on Query
      @refetchable(queryName: "ServiceCallListFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        representativeIds: { type: "[ID!]" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "ServiceCallJobRevisionFilterType" }
      ) {
        searchServiceCalls(
          searchTerm: $searchTerm
          representativeIds: $representativeIds
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [
            { snapshot: { statusOrder: ASC } }
            { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }
            { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: DESC } } } } }
            { id: ASC }
          ]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...ServiceCallList_RowFragment
              ...ServiceCallList_ItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          representativeIds: filters.get('representatives').map(({ id }) => id),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const edges = $data.searchServiceCalls?.edges;

  const mainColumns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
  ];

  const allColumns: ResponsiveGridColumnDefinition[] = [
    ...mainColumns,
    {
      id: 'po',
      label: t('list.column.poNumber'),
      size: '8rem',
    },
    { id: 'actions', label: '', size: 'minmax(6rem, auto)' },
  ];

  const columns = compact ? mainColumns : allColumns;

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <ServiceCallList_Row
      $key={node}
      write$key={write$key}
      orderByColumns={orderByColumns}
      onRefresh={() => gridRef.current?.goToFirstPage()}
    />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <ServiceCallList_Item $key={node} />);

  const handleItemClick = useCallback((id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId), [edges, onItemClick]);

  const rowSkeletonFactory = useSkeletonFactory(() => <RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ListSkeleton />);

  return (
    $data.searchServiceCalls && (
      <>
        <ServiceCallFiltersComponent $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={$data.searchServiceCalls}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={{
            "&[data-mode='grid']": {
              // Adds a padding in cells to improve readability, specially when elipsing.
              'li.responsive-grid__header  > *': {
                px: '1rem',
              },
              'li:not(.responsive-grid__header)  > *': {
                px: '0.5rem',
              },
            },

            // Controls the gap between the content of list items and the status chip.
            "&[data-mode='list'] > li": {
              gap: '0.5rem',
            },
          }}
          onItemClick={handleItemClick}
          {...gridProps}
        />
      </>
    )
  );
}

function ServiceCallFiltersComponent({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: ServiceCallListFiltersFragment$key;
  filters: ServiceCallFilters;
  onFiltersChange: (filters: ServiceCallResponsiveGridFilters) => void;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment ServiceCallListFiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
        representativeIds: { type: "[ID!]!" }
      ) {
        dispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label
          }
        }
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        representatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: ServiceCallResponsiveGridFilters = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof ServiceCallResponsiveGridFilters) return filters;
    return filters.toResponsiveGridFilters({
      dispatchBranches: $data.dispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
      equipmentKinds: $data.equipmentKinds,
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      representatives: $data.representatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<ServiceCallResponsiveGridFilters>
      filters={responsiveGridFilters}
      emptyFilters={ServiceCallResponsiveGridFilters.EMPTY}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={{
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      }}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search')}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.get('kinds')}
            label={t('list.column.kind')}
            options={serviceCallKinds}
            onChange={(kinds) => setState((prev) => prev.with({ kinds }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.get('capacities')}
            onChange={(value) => setState((prev) => prev.with({ capacities: value.map(({ capacity }) => capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.get('equipmentKinds')}
            onChange={(equipmentKinds) => setState((prev) => prev.with({ equipmentKinds }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={serviceCallStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <ServiceCallStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.get('dispatchBranches')}
            onChange={(dispatchBranches) => setState((prev) => prev.with({ dispatchBranches }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.get('representatives')}
            onChange={(representatives) => setState((prev) => prev.with({ representatives }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={([start, end]: DateRange<DateTime>) => {
              setState((prev) => prev.with({ arrivalDate: start?.isValid && end?.isValid ? { start, end } : null }));
            }}
          />
        ),
      ]}
    />
  );
}

export function ServiceCallList_Row({
  $key,
  write$key,
  orderByColumns,
  onRefresh: handleRefresh,
}: {
  $key: ServiceCallList_RowFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
  onRefresh: () => void;
}) {
  const { t } = useAmbientTranslation();

  const $data = useFragment(
    graphql`
      fragment ServiceCallList_RowFragment on ServiceCallJobRevision {
        ...ServiceCallListActionsFragment
        friendlyId
        snapshot {
          kind
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
              ...CreditCategoryChipFragment
            }
            assignedClientInfo {
              name
            }
          }
          client {
            orderNumber
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
              date
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={clientName} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span' title={clientName}>
            <NullableCell value={clientName} />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && <CreditCategoryChip $key={snapshot.clientBase.assignedClient} />}
        </Box>,

        <EllipsisedTypography
          key='worksite'
          variant='body2'
          component='span'
          title={snapshot?.projectBase.assignedWorksiteInfo?.name ?? ''}>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span' title={t(`kind.${snapshot?.kind}`)}>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <ServiceCallStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(t('locale', { ns: 'common' }))
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v)
                .setLocale(t('locale', { ns: 'common' }))
                .toLocaleString({
                  month: 'short',
                  day: 'numeric',
                })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <EllipsisedTypography key='po' variant='body2' title={snapshot?.client.orderNumber || ''}>
          <NullableCell value={snapshot?.client.orderNumber} />
        </EllipsisedTypography>,

        <ServiceCallListActions $key={$data} write$key={write$key} onRefresh={handleRefresh} key='actions' />,
      ])}
    </>
  );
}

function ServiceCallListActions({
  $key,
  write$key,
  onRefresh: handleRefresh,
}: {
  $key: ServiceCallListActionsFragment$key | null;
  write$key: AuthorizationWriteFragment$key;
  onRefresh: () => void;
}) {
  const $data = useFragment(
    graphql`
      fragment ServiceCallListActionsFragment on ServiceCallJobRevision {
        ...ServiceCallListDelayButtonFragment
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castServiceCallKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <Stack direction='row'>
        <ServiceCallListDelayButton fragmentKey={$data} onRefresh={handleRefresh} />
        <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
      </Stack>
    </RequireWrite>
  );
}

export function ServiceCallList_Item({ $key }: { $key: ServiceCallList_ItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const serviceCall = useFragment(
    graphql`
      fragment ServiceCallList_ItemFragment on ServiceCallJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = serviceCall;

  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];
  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={serviceCall.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={serviceCall.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>
        <ServiceCallStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

function RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

function ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}
